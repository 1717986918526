
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { ClaimDetailData, WSClaimViewData, DocType } from '@/views/Claim/WS/types'
import { claimViewDetail, claimViewList } from '@/API/claim/claimWS'
import { useRouter } from 'vue-router'
import usePage from '@/hooks/claim/usePage'
import downloadFile from '@/utils/claim/downloadFile'
export default defineComponent({
    setup() {
        // 分页逻辑
        const { paginationObj, pageChange, sizeChange } = usePage()
        // 当前payout round统计数据
        const claimDetailData = ref<ClaimDetailData>({
            payoutRoundName: '',
            vinCount: 0,
            totalPassed: 0,
            totalFailed: 0,
            passedTotalAmount: 0
        })
        const router = useRouter()
        const payoutRoundId = router.currentRoute.value.query.id

        // 下拉
        const statusVal = ref('')
        const finalResultVal = ref()
        const vinVal = ref<string>('')
        const statusList = ref<DocType[]>([
            {label: 'All', value: ''},
            {label: 'Activated', value: 1},
            {label: 'Submitted', value: 2},
            {label: 'SICCO Checked', value: 3},
            {label: 'SIPO Confirmed', value: 7},
            {label: 'Spot Checked', value: 8},
            {label: 'Controlling Confirmed', value: 9},
            {label: 'Confirmed to AFC', value: 10},
            {label: 'AFC Confirmed', value: 11},
        ])
        const finalResultList = ref<DocType[]>([
            {label:'Passed',value:1},
            {label:'Failed',value:2}
        ])
        const selectSize = ref<number>(20)
        const detailSize = ref<number>(50)
        // 表格数据 
        const tableHeight = window.innerHeight - 415
        const columns = reactive([
            { title: 'Dealer Code', dataIndex: 'dealerCode', width: 140 },
            { title: 'Dealer Name', dataIndex: 'dealerName', width: 220 },
            { title: 'VIN No', dataIndex: 'vinNo', width: 210 },
            { title: 'Make', dataIndex: 'make', width: 70 },
            { title: 'Model', dataIndex: 'model', width: 230 },
            { title: 'Loan Model', dataIndex: 'loanModel', width: 140 },
            { title: 'Financed Amount', dataIndex: 'financedAmount', width: 180 },
            { title: 'Days On Floorplan', dataIndex: 'daysOnFloorplan', width: 180 },
            { title: 'Payable Amount', dataIndex: 'payableAmount', width: 180 },
            { title: '15 Days Interest', dataIndex: 'interest15Days', width: 180 },
            { title: 'PT Extension Interest', dataIndex: 'ptExtensionInterest', width: 180 },
            { title: 'N DAYS', dataIndex: 'ndays', width: 120 },
            { title: 'M DAYS', dataIndex: 'mdays', width: 120 },
            { title: 'P DAYS', dataIndex: 'pdays', width: 120 },
            { title: 'Loan Given Date', dataIndex: 'loanGivenDate', width: 140 },
            { title: 'Settlement Date', dataIndex: 'settlementDate', width: 160 },
            { title: 'DLR Interest Start Date', dataIndex: 'dlrInterestStartDate', width: 180 },
            { title: 'Invoice Date', dataIndex: 'invoiceDate', width: 160 },
            { title: 'DST Interest Start Date', dataIndex: 'dstInterestStartDate', width: 190 },
            { title: 'DST Interest End Date', dataIndex: 'dstInterestEndDate', width: 190 },
            { title: 'Status', dataIndex: 'statusStr', width: 120 },
            { title: 'Final Result', dataIndex: 'finalResultStr', width: 120 },
            { title: 'Failed Reason', dataIndex: 'failedReason', width: 270 },
            // 				DST Interest End Date	Final result


        ])

        const claimViewData = ref<WSClaimViewData[]>([])
        // 查询
        const searchParams = reactive({
            current: 0,
            size: 20,
            payoutRoundId: '',
            status: '',
            finalResult: '',
            vin: ''
        })
        const getViewList = () => {
            searchParams.payoutRoundId = `${payoutRoundId}`
            const params = searchParams
            claimViewList({params}).then((res) =>{
                claimViewData.value = res.payoutRoundWSViewPageVOList
                paginationObj.total = res.totalElements
            })
        }
        const searchListHandle = () => {
            searchParams.status = statusVal.value;
            searchParams.finalResult = finalResultVal.value;
            searchParams.vin = vinVal.value;
            getViewList()
        }
        // 重置
        const resetHandle = () => {
            searchParams.status = statusVal.value = '';
            searchParams.finalResult = finalResultVal.value = '';
            searchParams.vin = vinVal.value = '';
        }
                // export
        const exportHandle = () => {
            const exportParams = {
                payoutRoundId: payoutRoundId,
            }
            const params = {
                    url: `claimapi/payout/round/ws_view/export`,
                    method: 'get',
                    params: exportParams,
                    fileName: `${+new Date()}.xlsx`
            }

            downloadFile(params, 'application/vnd-excel; char-set=UTF-8')
        }
        onMounted(() => {
            // 统计数据接口调用
            claimViewDetail({payoutRoundId: `${payoutRoundId}`}).then((res) => {
                claimDetailData.value = res
            })
            getViewList()
        })
        return {
            vinVal,
            statusVal,
            finalResultVal,
            statusList,
            finalResultList,
            paginationObj,
            pageChange,
            sizeChange,
            searchListHandle,
            resetHandle,
            selectSize,
            detailSize,
            claimDetailData,
            tableHeight,
            columns,
            claimViewData,
            searchParams,
            getViewList,
            payoutRoundId,
            exportHandle
        }
    },
})
